/* If you need to add @import statements, do so up here */

@import "jit-refresh.css";
/* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--color-fg: rgba(0, 0, 0, 0.85);
		--color-fg-secondary: rgba(0, 0, 0, 0.5);
		--color-fg-tertiary: rgba(0, 0, 0, 0.25);

		--color-link: #007aff;
	}

	html, body {
		font-feature-settings: "dlig", "ss07", "ss08", "cv01", "cv02", "cv03", "cv04", "cv05", "cv10";
	}
}

@layer components {
	#site {
		min-height: 100dvh;
		display: grid;
		grid-template-rows: 1fr auto;
		grid-template-columns: minmax(0, 1fr);
	}
}
